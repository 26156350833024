<i18n lang="yaml">
ja:
  head: 通知履歴
  more: もっとみる
  no-data: 通知はありません。
en:
  head: Notification history
  more: More
  no-data: No notification
</i18n>

<template>
  <!-- ▼▼▼ 通知履歴 ▼▼▼ -->
  <div class="ho-top-information-box">
    <div class="head">
      <div class="text">{{ i18n.t('head') }}</div>
      <IconNoticeBell class="icon" />
    </div>
    <template v-if="notifications.length">
      <div class="history-list-container">
        <!-- ▼▼▼ 通知履歴リスト ▼▼▼ -->
        <ul class="history-list">
          <li v-for="(history, index) in historyList" :key="index" class="item">
            <div class="icon">
              <HaLink class="link" :to="history.iconLink" redirect>
                <HmUserIcon class="image" :src="history.iconUrl" />
              </HaLink>
            </div>
            <div class="content">
              <div class="date">
                {{
                  isJa
                    ? formatJapaneseDate(history.createdAt)
                    : formatEnglishDate(history.createdAt)
                }}
              </div>
              <p class="text">
                <template v-if="typeof history.messageAction === 'string'">
                  <HaLink
                    class="link"
                    :to="history.messageAction"
                    :redirect="history.messageLinkRedirect"
                  >
                    {{ isJa ? history.message : history.messageEn }}</HaLink
                  >
                </template>
                <template v-else>
                  <div class="link" @click="history.messageAction">
                    {{ isJa ? history.message : history.messageEn }}
                  </div>
                </template>
              </p>
              <p class="user-name">
                <HaLink class="link" :to="history.userLink" redirect>
                  <span class="prefix">by</span>{{ history.userName }}
                </HaLink>
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div class="list-container">
        <ClientOnly>
          <HmSwiper
            :slides-per-view="1"
            :space-between="0"
            :auto-height="true"
            direction="vertical"
          >
            <SwiperSlide
              v-for="(history, index) in historyList"
              :key="index"
              class="slide"
            >
              <HaLink class="more-button" to="/mypage/notification/history">
                <div class="item-container">
                  <div class="icon">
                    <HmUserIcon class="image" :src="history.iconUrl" />
                  </div>
                  <div class="content">
                    <p class="text">
                      {{ isJa ? history.message : history.messageEn }}
                    </p>
                    <div class="info-container">
                      <p class="user-name">
                        <span class="prefix">by</span>{{ history.userName }}
                      </p>
                      <div class="date">
                        {{
                          isJa
                            ? formatJapaneseDate(history.createdAt)
                            : formatEnglishDate(history.createdAt)
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </HaLink>
            </SwiperSlide>
          </HmSwiper>
        </ClientOnly>
      </div>
      <div class="icon-container">
        <IconArrowNext class="icon-arrow" />
      </div>
      <!-- ▲▲▲ 通知履歴リスト ▲▲▲ -->
      <div class="more-button-container">
        <HaLink class="more-button" to="/mypage/notification/history">
          <HoMyVketButton class="button" outline>{{
            i18n.t('more')
          }}</HoMyVketButton>
        </HaLink>
      </div>
    </template>
    <template v-else>
      <p class="text">{{ i18n.t('no-data') }}</p>
    </template>
  </div>

  <!-- ▲▲▲ 通知履歴 ▲▲▲ -->
</template>

<script setup lang="ts">
import { SwiperSlide } from 'swiper/vue'
import { formatEnglishDate, formatJapaneseDate } from '@/utils/date-control'
import IconNoticeBell from '@/assets/icons/icon_notice_bell.svg'
import IconArrowNext from '@/assets/icons/icon-arrow-next.svg'
import type { EnterHousingWorld } from '@/composables/useInGame'

const i18n = useI18n()
const isJa = computed(() => i18n.locale.value === 'ja')

type Emits = {
  (emit: 'clickShare'): void
  (emit: 'enterHousingWorld', options: EnterHousingWorld): void
}
const emit = defineEmits<Emits>()

type HistroyItem = {
  iconLink: string
  iconUrl: string
  createdAt: string | Date
  messageAction: string | (() => void)
  messageLinkRedirect?: boolean
  message: string
  messageEn: string
  userLink: string
  userName: string
}
const { state: notificationState } = inject(notificationInjectionKey)!
const notifications = computed(() => notificationState.notifications)
const historyList = computed<HistroyItem[]>(() => {
  const result: HistroyItem[] = []
  // 通知の種類によるデータ形式の差を整形
  notifications.value.forEach((notification) => {
    if (notification?.followUser) {
      // ユーザーからのフォローの場合
      result.push({
        iconLink: `/profile/${notification.followUser.vketId}`,
        iconUrl: notification.followUser.icon.url ?? '',
        createdAt: notification.createdAt,
        messageAction: `/profile/${notification.followUser.vketId}`,
        message: 'フォローされました',
        messageEn: 'Followed',
        userLink: `/profile/${notification.followUser.vketId}`,
        userName: notification.followUser.name,
      })
    } else if (notification?.worldLike) {
      // ワールドへのいいねの場合
      result.push({
        iconLink: `/profile/${notification.worldLike.user.vketId}`,
        iconUrl: notification.worldLike.user.icon.url ?? '',
        createdAt: notification.createdAt,
        messageAction: `/play/world?worldId=${notification.worldLike.worldId}`,
        message: 'いいねされました',
        messageEn: 'Liked',
        userLink: `/profile/${notification.worldLike.user.vketId}`,
        userName: notification.worldLike.user.name,
      })
    } else if (notification?.housingScene) {
      // ルーム更新の場合
      result.push({
        iconLink: `/profile/${notification.housingScene.user.vketId}`,
        iconUrl: notification.housingScene.user.icon.url ?? '',
        createdAt: notification.createdAt,
        messageAction: () => {
          emit('enterHousingWorld', {
            worldId: notification.housingScene?.worldId || '',
          })
        },
        message: 'ルームが更新されました',
        messageEn: 'Room updated',
        userLink: `/profile/${notification.housingScene.user.vketId}`,
        userName: notification.housingScene.user.name,
      })
    } else if (notification?.event) {
      // イベントの場合
      result.push({
        iconLink: `/profile/${notification.event.user.vketId}`,
        iconUrl: notification.event.user.icon.url ?? '',
        createdAt: notification.createdAt,
        messageAction: notification.event.worldUrl,
        messageLinkRedirect: true,
        message: `${notification.event.name}が開催されました`,
        messageEn: `${notification.event.name} has been held`,
        userLink: `/profile/${notification.event.user.vketId}`,
        userName: notification.event.user.name,
      })
    } else if (notification?.vketboxSubmissionReview) {
      // vketboxのレビュー
      result.push({
        iconLink: `/mypage/assets/vketbox/${notification.vketboxSubmissionReview.vketboxSubmissionId}`,
        iconUrl:
          notification.vketboxSubmissionReview.vketboxSubmission.thumbnailUrl ??
          '',
        createdAt: notification.createdAt,
        messageAction: `/mypage/assets/vketbox/${notification.vketboxSubmissionReview.vketboxSubmissionId}`,
        messageLinkRedirect: false,
        message:
          notification.vketboxSubmissionReview.status === 'rejected'
            ? `${notification.vketboxSubmissionReview.vketboxSubmission.name}が差し戻しされました`
            : `${notification.vketboxSubmissionReview.vketboxSubmission.name}が公開可能になりました`,
        messageEn:
          notification.vketboxSubmissionReview.status === 'rejected'
            ? `${notification.vketboxSubmissionReview.vketboxSubmission.name} has been returned`
            : `${notification.vketboxSubmissionReview.vketboxSubmission.name} has been published`,
        userLink: '',
        userName: i18n.locale.value === 'ja' ? 'Vket Box' : 'Vket Box',
      })
    }
  })
  // 最大件数10件
  return result.slice(0, 10)
})
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;
@use '@/assets/styles/breakpoints.module.scss' as b;

.ho-top-information-box {
  background: rgba(v.$text-body, 0.5);
  border-radius: 10px;
  padding: v.space(8) 0 v.space(4);
  position: relative;
  width: 342px;

  @media screen and (max-width: b.$pc-content-medium-width) {
    width: 320px;
  }
  @include m.tb {
    align-items: center;
    border-radius: 25px;
    display: flex;
    height: 36px;
    justify-content: center;
    padding: v.space(0.5);
    width: 281px;
  }

  > .head {
    display: flex;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: v.space(-4);

    @include m.tb {
      align-items: center;
      background: #ffb546;
      border-radius: 50%;
      display: flex;
      height: 40px;
      justify-content: center;
      top: initial;
      width: 40px;
    }

    > .text {
      align-items: center;
      background: v.$primary-color;
      border-radius: 5px;
      color: v.$white;
      display: flex;
      font-size: 16px;
      font-weight: bold;
      height: 34px;
      justify-content: center;
      line-height: 1;
      min-width: 180px;
      padding: v.space(2);

      @include m.tb {
        display: none;
      }
    }

    > .icon {
      display: none;

      @include m.tb {
        align-items: center;
        display: flex;
        fill: v.$white;
        height: 20px;
        justify-content: center;
        width: 20px;
      }
    }
  }

  > .text {
    align-items: center;
    color: v.$white;
    display: flex;
    height: calc(80vh - v.$header-height-pc - 300px);
    justify-content: center;

    @include m.tb {
      font-size: 12px;
      height: 16px;
    }
  }

  > .history-list-container {
    padding: 0 v.space(4);

    @include m.tb {
      display: none;
    }
  }

  .more-button-container {
    display: flex;
    justify-content: center;
    margin-top: v.space(2);

    @include m.tb {
      display: none;
    }
  }

  .more-button {
    > .button {
      background: transparent;
      border-color: v.$white;
      color: v.$white;
      font-size: 14px;
      height: 40px;
      width: 150px;

      &:hover,
      &:focus {
        background: rgba(v.$text-body, 0.5);
        border-color: v.$primary-color;
        color: v.$primary-color;
      }
    }
  }

  .history-list {
    @extend %scroll-bar;
    display: grid;
    gap: v.space(2);
    grid-template-columns: 1fr;
    max-height: calc(100vh - v.$header-height-pc - v.space(126));
    overflow: auto;

    > .item {
      background: rgba(v.$text-body, 0.5);
      border-radius: 5px;
      display: flex;
      gap: 5px;
      padding: v.space(2);

      > .icon {
        border-radius: 50%;
        display: block;
        flex-shrink: 0;
        height: 50px;
        width: 50px;

        > .link {
          display: block;
          transition: filter 0.2s;

          &:hover {
            filter: brightness(1.1);
          }
        }
      }

      > .content {
        flex-grow: 1;

        > .date {
          color: v.$white;
          font-size: 12px;
        }
      }

      .text {
        background: v.$text-body;
        border-radius: 5px;
        margin-left: v.space(1);
        margin-top: v.space(1);
        padding: v.space(0.5) v.space(1);
        position: relative;

        &::before {
          background: v.$text-body;
          bottom: 0;
          clip-path: polygon(100% 0, 0 50%, 100% 100%);
          content: '';
          display: block;
          height: 7px;
          left: -4px;
          margin: auto;
          position: absolute;
          top: 0;
          width: 5px;
        }

        > .link {
          @include m.word-ellipsis(1);
          color: v.$white;
          cursor: pointer;
          font-size: 14px;

          &:hover {
            color: v.$primary-color;
          }
        }
      }

      .user-name {
        @include m.word-ellipsis(1);
        font-size: 12px;
        margin-top: v.space(1);

        > .link {
          color: v.$white;
          display: block;

          &:hover {
            color: v.$base-link-hover-color;
          }
        }

        .prefix {
          font-size: 10px;
          margin-right: v.space(1);
        }
      }
    }
  }

  .list-container {
    display: none;
    height: 41px;
    margin-left: v.space(12);
    overflow: hidden;
    width: 283px;
    @include m.tb {
      display: block;
    }
  }

  .item-container {
    align-items: center;
    display: flex;
    gap: 5px;
    justify-content: center;
    padding: v.space(0.5);

    > .icon {
      border-radius: 50%;
      display: block;
      flex-shrink: 0;
      height: 25px;
      width: 25px;

      > .link {
        display: block;
        transition: filter 0.2s;

        &:hover {
          filter: brightness(1.1);
        }
      }
    }

    > .content {
      flex-grow: 1;

      @include m.tb {
        color: v.$white;
      }

      > .date {
        color: v.$white;
        font-size: 10px;
      }
    }

    .text {
      border-radius: 5px;
      font-size: 12px;
      position: relative;

      > .link {
        @include m.word-ellipsis(1);
        color: v.$white;
        font-size: 14px;

        &:hover {
          color: v.$primary-color;
        }
      }
    }

    .user-name {
      @include m.word-ellipsis(1);
      font-size: 10px;
      margin-top: v.space(1);

      @include m.tb {
        margin-top: 0;
      }

      > .link {
        color: v.$white;
        display: block;

        &:hover {
          color: v.$base-link-hover-color;
        }
      }

      .prefix {
        font-size: 10px;
        margin-right: v.space(1);
      }
    }
  }

  .info-container {
    align-items: center;
    display: flex;
    justify-content: space-between;

    > .date {
      color: v.$white;
      font-size: 10px;
    }
  }

  .icon-container {
    display: none;

    @include m.tb {
      display: block;
    }
  }

  .icon-arrow {
    fill: v.$white;
    height: 12px;
    width: 12px;
  }

  .screen-reader {
    @include m.sr-only();
  }
}
</style>
